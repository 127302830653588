import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ApolloProvider } from '@apollo/client';
import apolloClient from './config/ApolloClient';
import 'raf/polyfill';
import { AppConfig, BrandConfiguration } from 'toca-config';
import ContextStore from './store/ContextStore';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

let brand = BrandConfiguration.GetBrandConfiguration();
const rootEl = document.getElementById('root');

const updateFavicon = () => {
  const link = document.querySelector('link[rel="shortcut icon"]');
  link.attributes['href'].value = brand.brandConfiguration.favicon;
};

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: AppConfig.launchDarklyClientId,
    context: {
      kind: 'user',
      key: AppConfig.launchDarklyDefaultUser,
    },
  });

  const RootApp = () => {
    return (
      updateFavicon(),
      (document.title = brand.brandConfiguration.name),
      (
        <LDProvider>
          <ApolloProvider client={apolloClient}>
            <ContextStore>
              <App />
            </ContextStore>
          </ApolloProvider>
        </LDProvider>
      )
    );
  };

  ReactDOM.render(<RootApp />, rootEl);
})();

console.log(`%c version: ${process.env.REACT_APP_VERSION} `, 'background: #0b6e69; color: #fff');
