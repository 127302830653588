import { BrandConfiguration } from 'toca-config';

let brand = BrandConfiguration.GetBrandConfiguration();

export const UserForRegistrationsLaunchDarkly = '15cec49f-0aeb-47ca-823d-7b6c4ce368d7';
export const footerMessage = BrandConfiguration.convertToBrand(
  brand,
  '© <BRANDNAME> 2020..... All rights reserved.'
);

export const maximumTopUpTriggerAmount = 1999;

export const maximumTopUpAmount = 2000;

export const registrationHeading = 'Enter your email address below and click register.';

export const registerConfirmHeading =
  'Registration successful. Follow the steps below to complete your registration.';

export const registerConfirmSteps = [
  'Go to your email account',
  `Find an email from ${brand.brandConfiguration.contactEmail}`,
  'Click on the link within the email to complete your registration and login.',
];

export const registerCompleteHeading = BrandConfiguration.convertToBrand(
  brand,
  'To setup your <BRANDNAME> account, please fill out the form below and click on the "Next" button.'
);

export const registerCompleteStudentHeading = BrandConfiguration.convertToBrand(
  brand,
  'Next you need to setup at least one <subuser> on your account. Please provide your <subuser> details below and click "Submit"'
);

export const registrationSuccessfulMessage = BrandConfiguration.convertToBrand(
  brand,
  'You will now be automatically redirected to the <BRANDNAME> user portal login page where you can login and place orders. You can also login and place orders using our <BRANDNAME> mobile app.'
);

export const registrationSuccessfulMessageNoApp = BrandConfiguration.convertToBrand(
  brand,
  'You will now be automatically redirected to the <BRANDNAME> user portal login page where you can login.'
);

export const brandTermAndConditions = BrandConfiguration.convertToBrand(
  brand,
  "<BRANDNAME>' Terms and Conditions of Use"
);

export const brandDailySpendLimitText = BrandConfiguration.convertToBrand(
  brand,
  'Daily <BRANDNAME> spend limit'
);

export const enterDailySpendLimit = BrandConfiguration.convertToBrand(
  brand,
  'Enter daily <BRANDNAME> spend limit amount'
);

export const userTypeOptions = [
  'I am a...',
  'I am a Parent',
  'I am a Staff Member',
  'I am a Staff Member with a Student',
  'Other',
];

export const titleOptions = ['Mr', 'Mrs', 'Miss', 'Ms', 'Mx', 'Ind'];

export const helpInfoText = 'Need Help?';

export const helpInfoNumber = '1300 590 044';

export const phoneRegExp = /^[+\d\- ]{10,13}$/;

export const alphabetOnlyRegex = /^[A-Za-z]+$/;

export const emailRegExp = /^\w+([-+'.\w]+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

export const FinanceAndProductStatementLink = 'https://www.flexischools.com.au/fsg-pds';

export const PrivacyPolicyLink = 'https://www.flexischools.com.au/legal-privacypolicy';

export const TargetMarketDeterminationLink =
  'https://prod-marketingsite-assets.s3-ap-southeast-2.amazonaws.com/Legal/flexischools_target-market-determination_oct21.pdf';

export const FinanceAndProductStatementPart2 =
  'Combined Financial Services Guide and Product Disclosure Statement,';

export const TargetMarketDetermination = 'Target Market Determination';

export const helpLink = 'https://www.flexischools.com.au/support';

export const flxSupportLink = 'https://community.flexischools.com.au/flx/s/contactsupport';

export const loginHeadingText = BrandConfiguration.convertToBrand(
  brand,
  'Login to your <BRANDNAME> account'
);

export const forgotPasswordHeading = 'Enter your email address below and click reset password';

export const forgotPasswordSuccess =
  'If this email address is linked to an active Flexischools account, you will receive an email with instructions (make sure you check your Spam folder, too). If you need further help, please use the "Need some help?" link below.';

export const changePasswordHeading = 'Please enter a new password for your account ';

export const changePasswordSuccessMessage =
  'Success! Your password has been changed. Please login from the <BRANDNAME> app.';

export const changePasswordFailureMessage =
  'Oops. Something went wrong. This issue may be temporary. Please go back and try again. If problem persists, please contact our support team.';

export const thankYouPageText = BrandConfiguration.convertToBrand(
  brand,
  'Registration successful! You can now log in to the <BRANDNAME> app using your email address and chosen password.'
);

export const ChangePasswordThankYouText = BrandConfiguration.convertToBrand(
  brand,
  'Your password has been successfully changed. You can now log in to the <BRANDNAME> app using your new password.'
);

export const preferencesList = [
  'Each placed order',
  'Each cancelled order',
  'When my wallet balance drops below:',
  'School Newsfeed Emails',
];

export const FlxPreferencesList = ['Spending notifications', 'Low balance notifications'];

export const PreferencesText = 'Email Preferences';

export const BraintreeDisclaimerText = BrandConfiguration.convertToBrand(
  brand,
  '<BRANDNAME> uses the Braintree service by PayPal to ensure the security and privacy of your credit card information. In conjunction with PayPal, we provide a secure environment for the transfer of your confidential data from your browser to our financial institution using industry standard encryption technology. For automated Top Ups, your credit card details are held only by PayPal using the latest encryption technology. They are not stored with <BRANDNAME> and are not re-transmitted at any time.'
);

export const AutoTopupDescription =
  "Automatic Top Ups mean you don't have to worry about your funds running out - whenever your balance falls below the 'trigger' level, it will automatically be topped up using your preferred payment method.";

export const UserKey = 'UserKey';

export const IsModernOrderingEnabled = 'IsModernOrderingEnabled';

export const BuzzUserId = 'BuzzUserId';

export const AccountHistoryTransactionHeaders = [
  { text: 'Date', width: '11%' },
  { text: 'Time', width: '8%' },
  { text: 'Student name', width: '10%' },
  { text: 'Order ID', width: '8%' },
  { text: 'Description', width: '35%' },
  { text: 'Debit', width: '9%' },
  { text: 'Credit', width: '9%' },
  { text: 'Balance', width: '9%' },
];

export const IsWebView = 'isWebView';

export const ApiReturnUrl = 'https://logoutflexischools.com/';

export const AccountClose_Line1 = BrandConfiguration.convertToBrand(
  brand,
  'To close your <BRANDNAME> account choose from the below options and confirm.'
);

export const AccountClose_Line1_ZeroBalance = BrandConfiguration.convertToBrand(
  brand,
  'Please confirm you wish to close your <BRANDNAME> account.'
);

export const AccountClose_Line2 = BrandConfiguration.convertToBrand(
  brand,
  'Once you close your account you will no longer be able to logon to the <BRANDNAME> web site or mobile app.'
);

export const AccountClose_RefundQuestion = BrandConfiguration.convertToBrand(
  brand,
  'What would you like to do with the funds in your <BRANDNAME> account?'
);

export const AccountCloseText_OptionDonate = 'Donate all funds in my account to Charity';

export const AccountCloseText_OptionRefund = 'Refund my balance back to me';

export const AccountCloseText_OptionPartialRefund =
  'Refund remaining balance back to my nominated bank account';

export const AccountCloseText_AccountText_Line1 =
  'To have these funds deposited to you bank account, please enter your BSB and account number below and click submit. Please enter the details carefully to ensure your funds reach the correct bank account.';

export const AccountCloseText_AccountText_Line2 =
  'The funds will be deposited into the above nominated bank account within 10 working days.';

export const RefundByDonationSuccess = BrandConfiguration.convertToBrand(
  brand,
  '$<Amount> has been donated to <BRANDNAME> nominated charity. Thank you for your support.'
);

export const RefundByDonationFail =
  'We were unable to refund to donation. Please complete the below to receive your funds to your nominated bank account.';

export const RefundByAccountSuccess =
  'Your request to have your funds despoisted to your nominated bank account has been submitted.';

export const RefundBySourceSuccess = 'was successfully refunded to your <METHODTYPE>';

export const RefundBySourcePartial_Line1 =
  'Unable to refund complete balance to your payment source.';

export const RefundBySourcePartial_Line2 =
  'However we were unable to to refund the remaining $<Amount>. Please choose an option below for this amount.';

export const RefundBySourceFail =
  'We were unable to refund $<Amount> to your card. Please complete the below to receive your funds to your nominated bank account.';

export const Refund_AccountClose = BrandConfiguration.convertToBrand(
  brand,
  'Your account is now closed and you will be taken to the <BRANDNAME> website.'
);

export const Login_Error_Cognito =
  'Your email address or password was not entered correctly. Please try again.';

export const Login_Error_Inactive =
  'The user is inactive. Please register again or contact our support team for assistance.';

export const Login_Error_Bad_Configure =
  'This user is configured in a way that does not allow login here. Please contact our support team for further assistance.';

export const Login_Error_AccountClosed =
  'This email address is on a closed account. If you wish to reopen this account please email help@flexischools.com.au requesting it be re-opened. Alternatively register a new account using a different email address.';

export const Login_Error_SSO =
  'Oops. Something went wrong. This issue may be temporary. Please go back and try again. If the problem persists, please contact our support team.';

export const Login_Error_Unknown = Login_Error_SSO;

export const Rego_Error = Login_Error_SSO;

export const Login_Error_Generic =
  'We are having trouble logging you in currently due to a temporary issue. Please try again shortly.';

export const CardNumberChargesMessage = BrandConfiguration.convertToBrand(
  brand,
  'By entering the <SUBUSER> Card Number on the form, you accept that charges incurred by presenting this card for payment will be billed to your <BRANDNAME> account.'
);

export const IsPoweredByFlexischoolsMessage = BrandConfiguration.convertToBrand(
  brand,
  '<BRANDNAME> is powered by Flexischools.'
);

export const SubUserOwnLogin = BrandConfiguration.convertToBrand(
  brand,
  'Give <subuser> their own <BRANDNAME> Online Ordering Login'
);

export const SubUserOwnLoginInfo = BrandConfiguration.convertToBrand(
  brand,
  'Enter <subuser> email address and they will get a registration email to setup an account with <BRANDNAME>. This will allow them to logon to the <BRANDNAME> App and web site to place online orders and view their account history.  This account is linked to your parent wallet, you can set a daily <BRANDNAME> spend limit if you wish to limit their spending from your wallet.'
);

export const KeepStudentInList = BrandConfiguration.convertToBrand(
  brand,
  'Press "OK" to proceed, or "Cancel" if you would like to keep the <subuser> in your list.'
);

export const RemoveStudentInList = BrandConfiguration.convertToBrand(
  brand,
  'will now be removed from your list of <subuser>s.'
);

export const AddASubUser = BrandConfiguration.convertToBrand(brand, 'Add a <subuser>');

export const SubUserClassUpdate = BrandConfiguration.convertToBrand(
  brand,
  'This <subuser> needs their <class> updated.'
);

export const SubUserCardNumberQuestion = BrandConfiguration.convertToBrand(
  brand,
  'What is a <subuser> card number?'
);

export const EnterSubUserCardNumber = BrandConfiguration.convertToBrand(
  brand,
  'Enter <subuser> card number'
);

export const SubUserCardNumberLabel = BrandConfiguration.convertToBrand(
  brand,
  '<SUBUSER> card number'
);

export const EnterStudentEmail = BrandConfiguration.convertToBrand(
  brand,
  "Enter <subuser>'s email address"
);

export const StudentEmail = BrandConfiguration.convertToBrand(brand, '<SUBUSER> email address');

export const SubUserValidateStatement = BrandConfiguration.convertToBrand(
  brand,
  "Please enter <subuser>'s email address"
);

export const SiteValidationText = 'Please select a valid Business Site.';

export const YearLevelValidationText = BrandConfiguration.convertToBrand(
  brand,
  'Please select <yearlevel>.'
);

export const SelectYearLevel = BrandConfiguration.convertToBrand(brand, 'Select <yearlevel>');

export const YearLevel = BrandConfiguration.convertToBrand(brand, '<YEARLEVEL>');

export const ClassValidationText = BrandConfiguration.convertToBrand(
  brand,
  'Please select <class>.'
);

export const SelectClass = BrandConfiguration.convertToBrand(brand, 'Select <class>');

export const ClassLabel_lowcase = BrandConfiguration.convertToBrand(brand, '<class>');

export const ClassLabel = BrandConfiguration.convertToBrand(brand, '<CLASS>');

export const impersonateInfo = {
  targetUserId: 'targetUserId',
  targetUserKey: 'targetUserKey',
  targetBrandId: 'targetBrandId',
};

export const SchoolLabel = BrandConfiguration.convertToBrand(brand, '<SCHOOL> name');

export const SearchSchool = BrandConfiguration.convertToBrand(brand, 'Search <SCHOOL>');

export const BraintreeRefreshEvent = 'refreshBTDropinEvent';

export const DeleteTargetMessage =
  'target deleted successfully. Any funds against this target have been transferred into the savings account.';

export const TargetCreatedMesage = 'target created successfully.';

export const StudentUnauthorizedBffError = 'You are not authorized to access this user';
export const StudentDoesNotExist = 'The user you are trying to load does not exist.';
export const UserDoesNotExist = 'User does not exist with userKey';
export const IsFlxParent = 'IsFlxParent';
export const FlxFaqsUrl = 'https://community.flexischools.com.au/flx/s/';

export const FlxReferralFormUrl = 'https://inloop.tfaforms.net/4923617';

export const PreUpdateStudentEvent = 'PreUpdateStudentEvent';
export const UpdateStudentProfileEvent = 'UpdateStudentProfileEvent';
export const UpdateStudentSettingsEvent = 'UpdateStudentSettingsEvent';
export const RemoveStudentEvent = 'RemoveStudentEvent';

export const studentNameRegex = /^[A-Za-z0-9-/.' ]+$/; // Allow  numbers, characters, dash, slash, full stop, apostrophes  and space
export const studentNameInvalidError = `Please enter a valid name- Only use A-z, 0-9 or symbols '.-/`;
export const isAccountOwner = 'isAccountOwner';
export const FlexischoolsLegalLink = 'https://www.flexischools.com.au/legal?fsapplink=external';

export enum signalRMessageTypesEnum {
  PLACE_ORDER_STATUS = 'PlaceOrderStatus',
}

export enum EClientNotificationEventKey {
  ACCOUNT_TOP_UP_SUCCEEDED = 'AccountTopUpSucceeded',
  ACCOUNT_TOP_UP_FAILED = 'AccountTopUpFailed',
}

export const GTM_TRACKER_NAME = 'GTM_TRACKER';

export const PERMANENT_COOKIE_SUFFIX = '__PERMANENT';

export const LEGACY_ROUTING_USER_KEYS = [
  // QA user keys
  '8704d7a5-110a-4a96-9ec2-068e2d001a7a',
  '8b3090f5-9211-44cd-8c8c-35d9a95338a1',
  // PROD user keys
  'd209722b-e7e6-4eb8-9444-a5ea9ad9db1c',
  '1d8d1121-6a66-450c-a41a-91bb28a20a1f',
  '3a1d9d54-fbe9-4097-ac32-8c1a546d3a0a',
  '25e39633-ef6f-4341-8b2c-7997bec04be2',
  'b7d9f4f8-7df8-4f7c-a0ea-4efce21c72fd',
];

export const RESET_OP_CART_CACHE_PARAM_KEY = 'resetCartCache';
