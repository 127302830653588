import { GTM_TRACKER_NAME } from 'config/Constants';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

export function useGoogleAnalyticsTracking(pathsToTrackUsingGTM: string[]) {
  const location = useLocation();

  useEffect(() => {
    if (pathsToTrackUsingGTM?.length) {
      // remove the starting / so that /flx-welcome/:studentKey becomes flx-welcome/:studentKey and split
      const currentPathSplit = location.pathname.replace('/', '').split('/');
      const pathMatches = pathsToTrackUsingGTM.some(path => {
        return path.split('/').every((pathSegment, index) => {
          const isPathSegmentRouteParam = pathSegment[0] === ':'; // eg: flx-welcome/:studentKey
          if (isPathSegmentRouteParam) {
            return !!currentPathSplit[index];
          }
          return currentPathSplit[index] === pathSegment;
        });
      });
      if (pathMatches) {
        ReactGA.pageview(location.pathname, [GTM_TRACKER_NAME]);
        return;
      }
    }
    ReactGA.pageview(location.pathname);
  }, [location.pathname, pathsToTrackUsingGTM]);
}
